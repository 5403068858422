<template src="./ShowList.html"></template>

<script>
import ShowEditor from '../../brick/ShowEditor';
import Vue from "vue";
export default {
  name: "ShowList",
  components: {ShowEditor},
  data: () => ({

    showlist_ready: false,

    selected:null,
    showlist:[],


  //
    search:'',
    headers:[
      {
        text:"Название",
        value:"name",
      },
      {
        text:"Статус",
        value:"tag_status_id",
      },
      {
        text:"Длительность",
        value:"time",
      },
      {
        text:"Возраст",
        value:"age",
      },
    ],
    selected_show_item:[
      {id:0}
    ],

    show_type_tag:[
      {id:70, name:"Обычный"},
      {id:71, name:"Премьера"},
      {id:72, name:"Гастроли"},
      {id:73, name:"Новогодний"},
      {id:74, name:"Золотая Репка"},
      {id:75, name:"Фестиваль"},
      {id:76, name:"Абонемент"},
      {id:77, name:"Сертификат"}
    ],

  }),

  created() {
    this.loadShowList();
  },

  methods:{

    getStatusName(status_id){

      //return this.show_type_tag.find(x => x.id === status_id).name;

      let nn = "Нет статуса";

      this.show_type_tag.find((x)=>{
        if (x.id === status_id){
          //console.log(x.name);
          nn = x.name;
        }
      })

      return nn;




    },

    loadShowList(){
      if (this.$store.getters.isAuthenticated){

        let pr = this.$store.state.api.loadShowList();
        //@TODO выводить культура ID
        pr.then( data =>{
          this.showlist = data;
          this.showlist_ready = true;
        })
      }
    },
    itemAdd(){
      this.$refs.ShowEditor.show();
      this.$refs.ShowEditor.addShowForm();
      this.selected_show_item = [{id:0}];
      // this.$refs.ShowEditor.flags.statusEdition = 0;
      this.$refs.ShowEditor.flagEdit([["statusEdition",0]])
    },
    itemSelect(e,id){
      console.log(e,id);
      this.$refs.ShowEditor.show();
      this.selected_show_item = [];
      this.selected_show_item.push(e);
      this.$refs.ShowEditor.loadShow(id);
      this.$refs.ShowEditor.flagEdit([["statusEdition",1]])
    },
    onAddToTable(){
      console.log("add");
      this.loadShowList();
    },
    onUpdateTable(info){
      console.log('onUpdateTable');
      console.log(info);
      let findShow = this.showlist.findIndex(el => el.id === info._raw.id);
      console.log(findShow)
      console.log(this.showlist)
      Vue.set(this.showlist, findShow, info.fields);
      //@TODO оптимизировать до одной строчки
    },
    onCloseShowTable(){
      this.selected_show_item = [{id:0}];
      this.$refs.ShowEditor.flagEdit([["statusEdition",0]])
    },
  }
}
</script>

<style scoped>

</style>
