var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('v-card-title',{staticClass:"mb-4"},[_vm._v(" Справочник наименований (спектакли, выставки, экскурсии и тп) ")]),(_vm.showlist_ready)?_c('v-card',{attrs:{"loading":!_vm.showlist_ready}},[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card-title',{staticClass:"my-0 py-0 px-4"},[_c('v-text-field',{staticClass:"my-0 py-0 ",attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Начните вводить название спектакля","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"mx-4",attrs:{"color":"green","left":"","outlined":""},on:{"click":function($event){return _vm.itemAdd()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.showlist,"items-per-page":50,"search":_vm.search,"dense":"","item-key":"id","single-select":""},on:{"click:row":function($event){return _vm.itemSelect($event,$event.id)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',_vm._l((item),function(value,name){return _c('div',[_vm._v(" "+_vm._s(name)+" : "+_vm._s(value)+" ")])}),0)]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.culture_id > 0)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-feather ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-feather ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.culture_id))])]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.tag_status_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusName(item.tag_status_id))+" ")]}}],null,false,572690515),model:{value:(_vm.selected_show_item),callback:function ($$v) {_vm.selected_show_item=$$v},expression:"selected_show_item"}})],1):_vm._e(),_c('ShowEditor',{ref:"ShowEditor",staticClass:"pa-3 pb-0",attrs:{"id":_vm.selected_show_item[0].id},on:{"onShowAdd":_vm.onAddToTable,"onShowClose":_vm.onCloseShowTable,"onShowUpdate":_vm.onUpdateTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }