<template src="./ShowEditor.html"></template>

<script>
export default {
  name: "ShowEditor",
  props:{
    id: Number,
    additional:{},
    width: {
      type: Number,
      default: 980
    },
  },
  data(){
    return {
      titleData:[
        {btnName:"Создать и закрыть",   formName:"Добавить новый спектакль"},
        {btnName:"Сохранить и закрыть", formName:"Редактирование спектакля"}
      ],
      flags:{
        showDataComplite:false,
        showDataChanged:false,//маркер наличия изменения
        isShow:false,
        loading: false,
        statusEdition:0,
        res: "Не сохранено",
        result:"Изменения не применены",
      },
      //
      emptyShowData:{
        show_id:0,
        name:"",
        author:"",
        genre:"",
        description:"",
        picture:"",
        video:"",
        pictures_count:0,
        tag_status_id:70,
        time:"",
        link:"",
        age:"",
        culture_id:0,
      },
      defaultShowData:{},
      editShowData:{},
      showDataModified:{},
      //
      show_type_tag:[
        {id:70,name:"Обычный",        code:"ST_NORMAL"},
        {id:71,name:"Премьера",       code:"ST_PREMIERE"},
        {id:72,name:"Гастроли",       code:"ST_TOUR"},
        {id:73,name:"Новогодний",     code:"ST_NEW_YEAR"},
        {id:74,name:"Золотая Репка",  code:"ST_REPKA"},
        {id:75,name:"Фестиваль",      code:"ST_FESTIVAL"},
        {id:76,name:"Абонемент",      code:"ST_SEASON_PASS"},
        {id:77,name:"Сертификат",     code:"ST_CERTIFICATE"}
      ],
    }
  },
  created(){
    this.dataControll();
  },
  methods:{
    dataControll(){
      this.defaultShowData = Object.assign({}, this.emptyShowData);
      this.editShowData = Object.assign({}, this.emptyShowData);
    },
    loadShow(id){
      // clear
      this.dataClear();
      // load
      this.loadDataShow(id);
      // refresh ui
      //@TODO ???
    },
    show(){
      this.flagEdit([["isShow",true]])
    },

    close(){
      this.flagEdit([["isShow",false],["statusEdition",0]])
    },
    dataClear(){
      this.flagEdit([["showDataComplite",false],["showDataChanged",false],["loading",false]])
        this.dataControll();
    },
    flagEdit(data){
      data.forEach((item)=>{
        this.flags[item[0]] = item[1];
      })
    },
    fillingData(data){
      this.defaultShowData = Object.assign({}, data);
      this.editShowData = Object.assign({}, data);
    },
    addShowForm(){
      this.dataControll();
      this.flagEdit([["showDataComplite",true]])
    },
    loadDataShow(id){
      if (this.$store.getters.isAuthenticated){
        let detail = {}
        let pr2 = this.$store.state.api.findShowByIDs(id, this.emptyShowData, detail);
        pr2.then( data =>{
          if(data.length > 0){
            this.fillingData(data[0]);
            this.flagEdit([["showDataComplite",true],["res","Загружено"]])
          }
        })

      }
    },
    watchDataItem(name){
      this.showDataModified.id = this.defaultShowData.id;
      this.flagEdit([["showDataChanged",true]])
      this.showDataModified[name] = this.editShowData[name];
    },
    resetShowData(){
      this.showDataModified = {};
      this.editShowData = Object.assign({}, this.defaultShowData);
      this.flagEdit([["showDataChanged",false]])
    },
    closeEdit(){
      this.dataClear();
      this.$emit('onShowClose');
      this.close();
    },
    updateShowGo(typeEvent,typeEdit) {
      if (!this.flags.showDataChanged) return false;
      if(typeEdit === 0) {
        let post_data = Object.assign({}, this.editShowData);
        delete post_data.show_id;
        post_data.id = 0;

        let pr2 = this.$store.state.api.addShow(post_data);
        pr2.then(data => {
          this.flagEdit([["result",this.name + " - Спектакль " + data + " добавлен"]])
          this.$emit('onShowAdd', true);
          if (typeEvent) {
            this.closeEdit();
          }
        })
      }else{
        let pr1 = this.$store.state.api.updateShow(this.showDataModified); //сохраняем элемент
        pr1.then(data => {
          if (data) {
            let item = {
              id:this.editShowData.id,
              name:this.editShowData.name,
              tag_status_id:this.editShowData.tag_status_id,
              time:this.editShowData.time,
              culture_id:this.editShowData.culture_id,
              age:this.editShowData.age,
              link:this.editShowData.link
            };
            let to_parent = {
              _raw:    this.showDataModified,
              fields: item
            }
            this.$emit('onShowUpdate', to_parent);
            if (typeEvent) {
              this.closeEdit();
            }

          }
        })
      }

    }
  }
}
</script>

<style scoped>

</style>
